import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import React from 'react'
import GlobalWrapper from '../components/globalWrapper'

const PrivacyPolicy = () => {
  return (
    <GlobalWrapper>
      <Seo title="Datenschutz" />
      <Nav caps wMax="xl" withBorder />
      <section>
        <Container>
          <Box pxMax="md" pyMax="lg">
            <h1>Datenschutz</h1>
            <h2>Datenschutzerklärung</h2>
            <p>
              Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
              Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend
              kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
              verbundenen Webseiten, Funktionen und Inhalte sowie externen
              Onlinepräsenzen, wie z.B. unser Social Media Profile auf
              (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
              Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
              „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
              Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
            </p>
            <h3>Verantwortlicher</h3>
            <p>
              Leo Rebay
              <br />
              Hytide GmbH
              <br />
              Türkenstr. 5b<br />
              85748 Garching
              <br />
              Deutschland
            </p>
            <h3>Arten der verarbeiteten Daten:</h3>
            <ul>
              <li>Bestandsdaten (z.B., Namen, Adressen)</li>
              <li>Kontaktdaten (z.B., E-Mail, Telefonnummern)</li>
              <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos)</li>
              <li>
                Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten)
              </li>
              <li>
                Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
                IP-Adressen)
              </li>
            </ul>
            <h3>Kategorien betroffener Personen</h3>
            <p>
              Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen
              wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
            </p>
            <h3>Zweck der Verarbeitung</h3>
            <ul>
              <li>
                Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
                Inhalte.
              </li>
              <li>
                Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
              </li>
              <li>Sicherheitsmaßnahmen.</li>
              <li>Reichweitenmessung/Marketing</li>
            </ul>
            <h3>Verwendete Begrifflichkeiten </h3>
            <p>
              „Personenbezogene Daten“ sind alle Informationen, die sich auf
              eine identifizierte oder identifizierbare natürliche Person (im
              Folgenden „betroffene Person“) beziehen; als identifizierbar wird
              eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
              zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
              identifiziert werden kann, die Ausdruck der physischen,
              physiologischen, genetischen, psychischen, wirtschaftlichen,
              kulturellen oder sozialen Identität dieser natürlichen Person
              sind.
            </p>
            <p>
              „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
              Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit
              und umfasst praktisch jeden Umgang mit Daten.
            </p>
            <p>
              „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in
              einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung
              zusätzlicher Informationen nicht mehr einer spezifischen
              betroffenen Person zugeordnet werden können, sofern diese
              zusätzlichen Informationen gesondert aufbewahrt werden und
              technischen und organisatorischen Maßnahmen unterliegen, die
              gewährleisten, dass die personenbezogenen Daten nicht einer
              identifizierten oder identifizierbaren natürlichen Person
              zugewiesen werden.
            </p>
            <p>
              „Profiling“ jede Art der automatisierten Verarbeitung
              personenbezogener Daten, die darin besteht, dass diese
              personenbezogenen Daten verwendet werden, um bestimmte persönliche
              Aspekte, die sich auf eine natürliche Person beziehen, zu
              bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung,
              wirtschaftliche Lage, Gesundheit, persönliche Vorlieben,
              Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
              Ortswechsel dieser natürlichen Person zu analysieren oder
              vorherzusagen.
            </p>
            <p>
              Als „Verantwortlicher“ wird die natürliche oder juristische
              Person, Behörde, Einrichtung oder andere Stelle, die allein oder
              gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
              von personenbezogenen Daten entscheidet, bezeichnet.
            </p>
            <p>
              „Auftragsverarbeiter“ eine natürliche oder juristische Person,
              Behörde, Einrichtung oder andere Stelle, die personenbezogene
              Daten im Auftrag des Verantwortlichen verarbeitet.
            </p>
            <h3>Maßgebliche Rechtsgrundlagen</h3>
            <p>
              Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die
              Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
              Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
              gilt Folgendes: Die Rechtsgrundlage für die Einholung von
              Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die
              Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
              Leistungen und Durchführung vertraglicher Maßnahmen sowie
              Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
              Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
              rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und
              die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
              berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
              Fall, dass lebenswichtige Interessen der betroffenen Person oder
              einer anderen natürlichen Person eine Verarbeitung
              personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
              lit. d DSGVO als Rechtsgrundlage.
            </p>
            <h3>Sicherheitsmaßnahmen</h3>
            <p>
              Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung
              des Stands der Technik, der Implementierungskosten und der Art,
              des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
              der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des
              Risikos für die Rechte und Freiheiten natürlicher Personen,
              geeignete technische und organisatorische Maßnahmen, um ein dem
              Risiko angemessenes Schutzniveau zu gewährleisten.
            </p>
            <p>
              Zu den Maßnahmen gehören insbesondere die Sicherung der
              Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
              Kontrolle des physischen Zugangs zu den Daten, als auch des sie
              betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
              Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
              eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
              Löschung von Daten und Reaktion auf Gefährdung der Daten
              gewährleisten. Ferner berücksichtigen wir den Schutz
              personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl
              von Hardware, Software sowie Verfahren, entsprechend dem Prinzip
              des Datenschutzes durch Technikgestaltung und durch
              datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).
            </p>
            <h3>Zusammenarbeit mit Auftragsverarbeitern und Dritten</h3>
            <p>
              Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
              Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
              offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
              die Daten gewähren, erfolgt dies nur auf Grundlage einer
              gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
              Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
              DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt
              haben, eine rechtliche Verpflichtung dies vorsieht oder auf
              Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
              Beauftragten, Webhostern, etc.).{' '}
            </p>
            <p>
              Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage
              eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht
              dies auf Grundlage des Art. 28 DSGVO.
            </p>
            <h3>Übermittlungen in Drittländer</h3>
            <p>
              Sofern wir Daten in einem Drittland (d.h. außerhalb der
              Europäischen Union (EU) oder des Europäischen Wirtschaftsraums
              (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von
              Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
              Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
              (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
              aufgrund einer rechtlichen Verpflichtung oder auf Grundlage
              unserer berechtigten Interessen geschieht. Vorbehaltlich
              gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder
              lassen wir die Daten in einem Drittland nur beim Vorliegen der
              besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h.
              die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien,
              wie der offiziell anerkannten Feststellung eines der EU
              entsprechenden Datenschutzniveaus (z.B. für die USA durch das
              „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller
              vertraglicher Verpflichtungen (so genannte
              „Standardvertragsklauseln“).
            </p>
            <h3>Rechte der betroffenen Personen</h3>
            <p>
              Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
              betreffende Daten verarbeitet werden und auf Auskunft über diese
              Daten sowie auf weitere Informationen und Kopie der Daten
              entsprechend Art. 15 DSGVO.
            </p>
            <p>
              Sie haben entsprechend. Art. 16 DSGVO das Recht, die
              Vervollständigung der Sie betreffenden Daten oder die Berichtigung
              der Sie betreffenden unrichtigen Daten zu verlangen.
            </p>
            <p>
              Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
              dass betreffende Daten unverzüglich gelöscht werden, bzw.
              alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der
              Verarbeitung der Daten zu verlangen.
            </p>
            <p>
              Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
              die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu
              erhalten und deren Übermittlung an andere Verantwortliche zu
              fordern.{' '}
            </p>
            <p>
              Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei
              der zuständigen Aufsichtsbehörde einzureichen.
            </p>
            <h3>Widerrufsrecht</h3>
            <p>
              Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3
              DSGVO mit Wirkung für die Zukunft zu widerrufen
            </p>
            <h3>Widerspruchsrecht</h3>
            <p>
              Sie können der künftigen Verarbeitung der Sie betreffenden Daten
              nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
              Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke
              der Direktwerbung erfolgen.
            </p>
            <h3>Löschung von Daten</h3>
            <p>
              Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17
              und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
              Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich
              angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald
              sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
              Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
              Sofern die Daten nicht gelöscht werden, weil sie für andere und
              gesetzlich zulässige Zwecke erforderlich sind, wird deren
              Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und
              nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die
              aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
              müssen.
            </p>
            <p>
              Nach gesetzlichen Vorgaben in Deutschland, erfolgt die
              Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257
              Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen,
              Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung
              relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2
              und 3, Abs. 4 HGB (Handelsbriefe).
            </p>
            <p>
              Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
              insbesondere für 7 J gemäß § 132 Abs. 1 BAO
              (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege,
              Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.),
              für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei
              Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen,
              Telekommunikations-, Rundfunk- und Fernsehleistungen, die an
              Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die
              der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
            </p>
            <p />
            <h3>Geschäftsbezogene Verarbeitung</h3>
            <p />
            Zusätzlich verarbeiten wir
            <ul>
              <li>
                Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit,
                Kundenkategorie)
              </li>
              <li>Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)</li>
            </ul>
            von unseren Kunden, Interessenten und Geschäftspartner zwecks
            Erbringung vertraglicher Leistungen, Service und Kundenpflege,
            Marketing, Werbung und Marktforschung
            <p />
            <h3>Bestellabwicklung im Onlineshop und Kundenkonto</h3>
            <p />
            <p>
              Wir verarbeiten die Daten unserer Kunden im Rahmen der
              Bestellvorgänge in unserem Onlineshop, um ihnen die Auswahl und
              die Bestellung der gewählten Produkte und Leistungen, sowie deren
              Bezahlung und Zustellung, bzw. Ausführung zu ermöglichen.
            </p>
            <p>
              Zu den verarbeiteten Daten gehören Bestandsdaten,
              Kommunikationsdaten, Vertragsdaten, Zahlungsdaten und zu den von
              der Verarbeitung betroffenen Personen gehören unsere Kunden,
              Interessenten und sonstige Geschäftspartner. Die Verarbeitung
              erfolgt zum Zweck der Erbringung von Vertragsleistungen im Rahmen
              des Betriebs eines Onlineshops, Abrechnung, Auslieferung und der
              Kundenservices. Hierbei setzen wir Session Cookies für die
              Speicherung des Warenkorb-Inhalts und permanente Cookies für die
              Speicherung des Login-Status ein.
            </p>
            <p>
              Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b
              (Durchführung Bestellvorgänge) und c (Gesetzlich erforderliche
              Archivierung) DSGVO. Dabei sind die als erforderlich
              gekennzeichneten Angaben zur Begründung und Erfüllung des
              Vertrages erforderlich. Die Daten offenbaren wir gegenüber Dritten
              nur im Rahmen der Auslieferung, Zahlung oder im Rahmen der
              gesetzlichen Erlaubnisse und Pflichten gegenüber Rechtsberatern
              und Behörden. Die Daten werden in Drittländern nur dann
              verarbeitet, wenn dies zur Vertragserfüllung erforderlich ist
              (z.B. auf Kundenwunsch bei Auslieferung oder Zahlung).
            </p>
            <p>
              Nutzer können optional ein Nutzerkonto anlegen, indem sie
              insbesondere ihre Bestellungen einsehen können. Im Rahmen der
              Registrierung, werden die erforderlichen Pflichtangaben den
              Nutzern mitgeteilt. Die Nutzerkonten sind nicht öffentlich und
              können von Suchmaschinen nicht indexiert werden. Wenn Nutzer ihr
              Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf
              das Nutzerkonto gelöscht, vorbehaltlich deren Aufbewahrung ist aus
              handels- oder steuerrechtlichen Gründen entspr. Art. 6 Abs. 1 lit.
              c DSGVO notwendig. Angaben im Kundenkonto verbleiben bis zu dessen
              Löschung mit anschließender Archivierung im Fall einer rechtlichen
              Verpflichtung. Es obliegt den Nutzern, ihre Daten bei erfolgter
              Kündigung vor dem Vertragsende zu sichern.
            </p>
            <p>
              Im Rahmen der Registrierung und erneuter Anmeldungen sowie
              Inanspruchnahme unserer Onlinedienste, speichern wir die
              IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
              Speicherung erfolgt auf Grundlage unserer berechtigten Interessen,
              als auch der Nutzer an Schutz vor Missbrauch und sonstiger
              unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
              grundsätzlich nicht, außer sie ist zur Verfolgung unserer
              Ansprüche erforderlich oder es besteht hierzu eine gesetzliche
              Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.
            </p>
            <p>
              Die Löschung erfolgt nach Ablauf gesetzlicher Gewährleistungs- und
              vergleichbarer Pflichten, die Erforderlichkeit der Aufbewahrung
              der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
              Archivierungspflichten erfolgt die Löschung nach deren Ablauf
              (Ende handelsrechtlicher (6 Jahre) und steuerrechtlicher (10
              Jahre) Aufbewahrungspflicht).
            </p>
            <p />
            <h3>
              Administration, Finanzbuchhaltung, Büroorganisation,
              Kontaktverwaltung
            </h3>
            <p />
            <p>
              Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
              Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der
              gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei
              verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung
              unserer vertraglichen Leistungen verarbeiten. Die
              Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6
              Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden,
              Interessenten, Geschäftspartner und Websitebesucher betroffen. Der
              Zweck und unser Interesse an der Verarbeitung liegt in der
              Administration, Finanzbuchhaltung, Büroorganisation, Archivierung
              von Daten, also Aufgaben die der Aufrechterhaltung unserer
              Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung
              unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf
              vertragliche Leistungen und die vertragliche Kommunikation
              entspricht den, bei diesen Verarbeitungstätigkeiten genannten
              Angaben.
            </p>
            <p>
              Wir offenbaren oder übermitteln hierbei Daten an die
              Finanzverwaltung, Berater, wie z.B., Steuerberater oder
              Wirtschaftsprüfer sowie weitere Gebührenstellen und
              Zahlungsdienstleister.
            </p>
            <p>
              Ferner speichern wir auf Grundlage unserer
              betriebswirtschaftlichen Interessen Angaben zu Lieferanten,
              Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks
              späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen
              Daten, speichern wir grundsätzlich dauerhaft.
            </p>
            <p />
            <h3>Kontaktaufnahme</h3>
            <p />
            <p>
              Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
              Telefon oder via sozialer Medien) werden die Angaben des Nutzers
              zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art.
              6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können
              in einem Customer-Relationship-Management System ("CRM System")
              oder vergleichbarer Anfragenorganisation gespeichert werden.
            </p>
            <p>
              Wir löschen die Anfragen, sofern diese nicht mehr erforderlich
              sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner
              gelten die gesetzlichen Archivierungspflichten.
            </p>
            <p />
            <h3>Newsletter</h3>
            <p />
            <p>
              Mit den nachfolgenden Hinweisen informieren wir Sie über die
              Inhalte unseres Newsletters sowie das Anmelde-, Versand- und das
              statistische Auswertungsverfahren sowie Ihre Widerspruchsrechte
              auf. Indem Sie unseren Newsletter abonnieren, erklären Sie sich
              mit dem Empfang und den beschriebenen Verfahren einverstanden.
            </p>
            <p>
              Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und
              weitere elektronische Benachrichtigungen mit werblichen
              Informationen (nachfolgend „Newsletter“) nur mit der Einwilligung
              der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen
              einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben
              werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im
              Übrigen enthalten unsere Newsletter Informationen zu unseren
              Leistungen und uns.
            </p>
            <p>
              Double-Opt-In und Protokollierung: Die Anmeldung zu unserem
              Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie
              erhalten nach der Anmeldung eine E-Mail, in der Sie um die
              Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist
              notwendig, damit sich niemand mit fremden E-Mailadressen anmelden
              kann. Die Anmeldungen zum Newsletter werden protokolliert, um den
              Anmeldeprozess entsprechend den rechtlichen Anforderungen
              nachweisen zu können. Hierzu gehört die Speicherung des Anmelde-
              und des Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso
              werden die Änderungen Ihrer bei dem Versanddienstleister
              gespeicherten Daten protokolliert.
            </p>
            <p>
              Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es
              aus, wenn Sie Ihre E-Mailadresse angeben. Optional bitten wir Sie
              einen Namen, zwecks persönlicher Ansprache im Newsletters
              anzugeben.
            </p>
            <p>
              Der Versand des Newsletters und die mit ihm verbundene
              Erfolgsmessung erfolgen auf Grundlage einer Einwilligung der
              Empfänger gem. Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs. 2
              Nr. 3 UWG oder falls eine Einwilligung nicht erforderlich ist, auf
              Grundlage unserer berechtigten Interessen am Direktmarketing gem.
              Art. 6 Abs. 1 lt. f. DSGVO i.V.m. § 7 Abs. 3 UWG.
            </p>
            <p>
              Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
              unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO.
              Unser Interesse richtet sich auf den Einsatz eines
              nutzerfreundlichen sowie sicheren Newslettersystems, das sowohl
              unseren geschäftlichen Interessen dient, als auch den Erwartungen
              der Nutzer entspricht und uns ferner den Nachweis von
              Einwilligungen erlaubt.
            </p>
            <p>
              Kündigung/Widerruf - Sie können den Empfang unseres Newsletters
              jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen. Einen
              Link zur Kündigung des Newsletters finden Sie am Ende eines jeden
              Newsletters. Wir können die ausgetragenen E-Mailadressen bis zu
              drei Jahren auf Grundlage unserer berechtigten Interessen
              speichern bevor wir sie löschen, um eine ehemals gegebene
              Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten
              wird auf den Zweck einer möglichen Abwehr von Ansprüchen
              beschränkt. Ein individueller Löschungsantrag ist jederzeit
              möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung
              bestätigt wird.
            </p>
            <p />
            <h3>Onlinepräsenzen in sozialen Medien</h3>
            <p />
            <p>
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
              Plattformen, um mit den dort aktiven Kunden, Interessenten und
              Nutzern kommunizieren und sie dort über unsere Leistungen
              informieren zu können. Beim Aufruf der jeweiligen Netzwerke und
              Plattformen gelten die Geschäftsbedingungen und die
              Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.
            </p>
            <p>
              Soweit nicht anders im Rahmen unserer Datenschutzerklärung
              angegeben, verarbeiten wir die Daten der Nutzer sofern diese mit
              uns innerhalb der sozialen Netzwerke und Plattformen
              kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen
              oder uns Nachrichten zusenden.
            </p>
            <p />
            <h3>Einbindung von Diensten und Inhalten Dritter</h3>
            <p />
            <p>
              Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
              berechtigten Interessen (d.h. Interesse an der Analyse,
              Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
              im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder
              Serviceangebote von Drittanbietern ein, um deren Inhalte und
              Services, wie z.B. Videos oder Schriftarten einzubinden
              (nachfolgend einheitlich bezeichnet als “Inhalte”).
            </p>
            <p>
              Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte,
              die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse
              die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse
              ist damit für die Darstellung dieser Inhalte erforderlich. Wir
              bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
              Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
              verwenden. Drittanbieter können ferner so genannte Pixel-Tags
              (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für
              statistische oder Marketingzwecke verwenden. Durch die
              "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
              Seiten dieser Website ausgewertet werden. Die pseudonymen
              Informationen können ferner in Cookies auf dem Gerät der Nutzer
              gespeichert werden und unter anderem technische Informationen zum
              Browser und Betriebssystem, verweisende Webseiten, Besuchszeit
              sowie weitere Angaben zur Nutzung unseres Onlineangebotes
              enthalten, als auch mit solchen Informationen aus anderen Quellen
              verbunden werden.
            </p>
            <h3>Google-Services</h3>
            <p>
              Diese Seite nutzt Google-Services zur Autovervollständigung von
              Adressen und der automatischen Anfahrtsberechnung in unserem
              Mietpreisrechner.
            </p>
            <p>
              Weitere Informationen zum Umgang mit Ihren Daten finden Sie in der{' '}
              <a href="https://policies.google.com/privacy">
                Google-Datenschutzerklärung
              </a>
              .
            </p>
          </Box>
        </Container>
      </section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default PrivacyPolicy
